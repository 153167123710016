<template lang="pug">
    .main-wrapper.caixas-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "contas" }'> Conta Bancária</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } conta bancária`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.cd_banco.$error }")
                                label.form-label Banco:
                                Dropdown(v-model='$v.model.cd_banco.$model' :options='options.bancos'
                                    dataKey='value' optionLabel='nm_banco' optionValue='id' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
                                    .form-message--error(v-if="!$v.model.cd_banco.required") <b>Banco</b> é obrigatório.

                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.cd_agencia.$error }")
                                label.form-label Agência:
                                 Dropdown(v-model='$v.model.cd_agencia.$model' :options='options.agencias' :disabled='!model.cd_banco'
                                    dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_agencia.$error')
                                    .form-message--error(v-if="!$v.model.cd_agencia.required") <b>Agência</b> é obrigatório.

                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.nr_conta.$error }")
                                label.form-label Conta:
                                InputText(type='text' v-model='$v.model.nr_conta.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_conta.$error')
                                    .form-message--error(v-if="!$v.model.nr_conta.required") <b>Conta</b> é obrigatório.
                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.nr_conta.$error }")
                                label.form-label Operação:
                                InputText(type='text' v-model='$v.model.nr_operacao.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_operacao.$error')
                                    .form-message--error(v-if="!$v.model.nr_operacao.required") <b>Operação</b> é obrigatório.
                            .p-col-6
                                label.form-label Valor:
                                CurrencyInput(
                                    v-model='model.nr_saldo'
                                    locale='pt-BR'
                                    :disabled='!!model.id'
                                    :auto-decimal-mode='true'
                                    )
                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                                label.form-label Tipo de conta:
                                Dropdown(v-model='$v.model.ie_tipo.$model' :options='options.tipo_conta'
                                    dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo de conta</b> é obrigatório.
                            .p-col-12
                                label.form-label Observações:
                                Textarea(v-model='model.ds_observacoes' placeholder='(Opcional)' :autoResize="true" rows="5")

                            //- .p-col-12
                            //-     label.form-label.mb-1 Status:
                            //-     InputSwitch(v-model='model.ie_status' :disabled='model.id')

                            .p-col-12.mt-2
                                Button(label='Salvar' type="submit")


</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from "primevue/inputswitch";
    import Textarea from 'primevue/textarea'
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
    import { ContasBancarias, Bancos, Agencia, DominioValor } from './../../middleware'
    import { required } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip, InputSwitch, Textarea, CurrencyInput
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            this.options.bancos.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
            Bancos.findAll().then(response => {
                if (response.status == 200) {
                    this.options.bancos = response.data
                }
            })
            DominioValor.findAll({ds_mnemonico: 'TIPO_CONTA_BANCARIA'}).then((response) => {
					if(response.status === 200) {
						response.data['TIPO_CONTA_BANCARIA'].valores.forEach(item => {
							this.options.tipo_conta.push({value: item.ie_valor, label: item.ds_valor})
						})
					}
				})
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                    ContasBancarias.find(id).then(response => {
                    if (response.status == 200) {
                       this.model = response.data
                       this.model.nr_saldo = +this.model.nr_saldo
                    }
                    this.waiting = false
                })
            } else this.waiting = false

        },
        data () {
            return {
                model: {
                    cd_agencia: null,
                    nr_conta: '',
                    nr_operacao: '',
                    nr_saldo: 0,
                    cd_banco: null,
                    ie_tipo: '',
                    ds_observacoes: '',
                },
                options: {
                    bancos: [],
                    tipo_conta: [],
                    agencias: []
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    cd_agencia: { required },
                    nr_conta: { required },
                    nr_operacao: { required },
                    cd_banco: { required },
                    ie_tipo: {required}
                }
            }

            return v
        },
        watch: {
            'model.cd_banco': function (val) {
                this.options.agencias = []
                Agencia.findAll({cd_banco: val}).then(response => {
                    if(response.status == 200) {
                        response.data.forEach(item => {
                            this.options.agencias.push({
                                label: 'Agência ' + item.nr_agencia +  ' - ' + item.nm_banco,
                                value: item.id,
                            })
                        })
                    }
                    console.log(response)
                })
            },
        },
        methods: {

            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true

                ContasBancarias.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (!dataSend.id) this.$router.push({ name: 'contas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })


            }
         }
      }
</script>
